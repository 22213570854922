import React from "react";
import '../../components/Layout/layout.css';

const CustomhyperlinksSection = ({ children }) => {
  return (
   
    <a className="page-links"
      href={children}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>

  
  );
};

export default CustomhyperlinksSection;
